import {
    Input,
    Form,
    Upload,
    Divider,
    InputNumber,
    Typography,
    Space, Select, Image,
    DatePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {PlusOutlined} from "@ant-design/icons";
import {useUserSessionContext} from "@/repo/context";
import ContentEditor from "@/component/dialog/content-editor";
import {Card, CardItem} from "@/repo/model/card";
import GoodPicker from './good-picker';
import UserPicker from "@/view/card/user-picker";
import {useEffect, useState} from "react";
import styles from './input.module.css';
import moment, {Dayjs} from "dayjs";
import {defaultCurrency} from "@/repo/model/currency";
import {responseTransfer} from "@/repo/response/transfer";
import {User} from "@/repo/model/user";

export default (props: {
    card?: Partial<Card>,
    agentUser?: User,
    onComplete: (card?: Card) => void
}) => {
    const {card, onComplete, agentUser} = props;
    const {repo,userSession} = useUserSessionContext();
    const [templateCards, setTemplateCards] = useState<Card[]>();
    const [templateCard, setTemplateCard] = useState<Card>();
    const [cardItems, setCardItems] = useState<CardItem[]>();
    const [searchUserContent, setSearchUserContent] = useState<string>();
    const [searchAgentUserContent, setSearchAgentUserContent] = useState<string>();
    const [cardCover, setCardCover] = useState<string>();
    const [companyTags, setCompanyTags] = useState<{
        id: string;
        name: string
    }[]>();
    const [platformTags, setPlatformTags] = useState<{
        id: string;
        name: string
    }[]>();
    const allowedUserIds = ["8999","23807","60","133","25387","33883","41405","42131","42800"];
    const user_id =  userSession?.user?.id ?? "";
    useEffect(() => {
        if (card && repo) {
            setCardItems(card.items);
            setCardCover(card.cover);
            if (!card.id) repo.listTemplateCard()
                    .then(v => {
                        setTemplateCards(v.items);
                        if (v.items && v.items.length > 0) setTemplateCard(v.items[0]);
                    }); else if (!card.items) repo.listCardItem(card.id, {fillGood: true})
                .then(v => setCardItems(v.items))
                .catch();
            if (!companyTags) repo.listAfterSaleTags({type: 2})
                .then(v => setCompanyTags(v.map(e => ({
                    id: e.id.toString(),
                    name: e.name
                }))));
            if (!platformTags) repo.listAfterSaleTags({type: 3})
                .then(v => setPlatformTags(v.map(e => ({
                    id: e.id.toString(),
                    name: e.name
                }))));
        } else {
            setCardItems(undefined);
            setTemplateCard(undefined);
            setTemplateCards(undefined);
        }
    }, [card, repo]);

    useEffect(() => {
        setCardItems(undefined);
        if (templateCard && repo) {
            setCardCover(templateCard.cover);
            const items = templateCard.items;
            items ? setCardItems(items) : repo.listCardItem(templateCard.id, {fillGood: true})
                .then(v => setCardItems(v.items))
                .catch();
        }
    }, [templateCard, repo]);
    const cardImageStyle = {width: 330, height: 220};
    const getUpdateItems = () => {
        const setItems: {
             goodId: string;
             count?: number;
             consume?: number;
         }[] = [];
        cardItems?.forEach(e => {
            const gid = e.good?.id;
            if (gid) setItems.push({
                goodId: gid,
                count: e.count,
                consume: e.consume
            });
        });
       return setItems;
    }
    const currency = card?.currency ?? defaultCurrency;
    const hasDefaultPresent = (card?.card?.birthdayPresent?.length ?? 0) > 0;
    return <ContentEditor
        onSubmit={async f => {
            if (repo) {
                if ((searchUserContent?.length ?? 0) > 0) {
                    f.setFieldValue('user', {
                        phone: searchUserContent,
                        name: f.getFieldValue('userName') ?? searchUserContent
                    });
                    await repo.listUser({search: searchUserContent})
                        .then(v => v.items.length > 0 ? f.setFieldValue('user', v.items[0]) : undefined);
                }
                if ((searchAgentUserContent?.length ?? 0) > 0) {
                    await repo.listUser({search: searchAgentUserContent})
                        .then(v => v.items.length > 0 ? f.setFieldValue('agentUser', v.items[0]) : undefined);
                }
            }
            f.submit();
        }}
        childrenAlign='start'
        item={card as ((Card&{time?:Dayjs, companyId?: string}) | undefined)}
        onInit={t => ({
            ...t,
            time: t?.createTime ? moment(new Date(t.createTime)): undefined,
            companyId: t.company?.id,
            agentUser: t.agentUser ?? agentUser
        })}
        onComplete={onComplete}
        onCreateItem={repo ? item => repo.createCard({...item,
                company: item.companyId ? {id:  item.companyId,} : undefined,
                cover: cardCover,
                createTime: item.time?.toISOString(),
                items: cardItems,
                card: templateCard})
                /*.then(c => {
                    const items = getUpdateItems();
                    return items.length > 0 ? repo.setCardItem(c.id, items)
                        .then(() => ({...c, itemCount: items.length})) : c;
                })*/
                .then(c => ({...c,
                    user: item.user,
                    agentUser: item.agentUser,
                    card: templateCard,
                    itemCount: cardItems?.length,
                    currency: item.currency}))
            : undefined}
        onUpdateItem={repo ? item => repo.updateCard(card?.id ?? '', {...item,
                company: item.companyId ? {id:  item.companyId,} : undefined,
                items: cardItems,
                cover: cardCover
            })
                /*.then(c => {
                    const items = getUpdateItems();
                    return repo.setCardItem(c.id, items)
                        .then(() => ({...c, itemCount: items.length}));
                })*/
                .then(() => ({...card, ...item, id: card?.id ?? '', itemCount: cardItems?.length,}))
            : undefined}
        titleBuilder={isNew => isNew ? <Space size={20} align='center'>
            新增年卡
            <Select placeholder={'年卡模版'}
                    style={{minWidth: 120}}
                    value={templateCard?.id}
                    onChange={v => setTemplateCard(templateCards?.find(e => e.id === v))}
                    loading={!templateCards}>
                {templateCards?.map(v => <Select.Option
                    value={v.id}
                    key={v.id}>
                    {v.name}
                </Select.Option>)}
            </Select>
        </Space> : <Space direction="vertical" size={0}>
            编辑年卡
            <Typography.Text type="secondary"
                             copyable={{tooltips: '复制年卡ID'}}
                             style={{fontSize: 10}}>{card?.id}</Typography.Text>
        </Space>}
        contentBuilder={(isNew, form) => <>
            <Form.Item label="所属用户" required
                       name="user"
                       valuePropName='user'
                       rules={[{required: !searchUserContent || searchUserContent.length <= 0}]}>
                <UserPicker
                    disabled={!isNew}
                    onSearch={setSearchUserContent}
                    onPick={u => {
                        form.setFieldValue("user", u);
                        setSearchUserContent(undefined);
                    }} />
            </Form.Item>
            {form.getFieldValue("user") ? null : <Form.Item label="用户昵称" name='userName'>
                <Input placeholder={'输入开通用户昵称'} />
            </Form.Item>}
            <Form.Item label="名称" required={!templateCard}
                       name="name"
                       rules={[{required: !templateCard}]}>
                <Input placeholder={templateCard?.name ?? '输入年卡名称'} />
            </Form.Item>
            <Form.Item name="deliveryCount" label="发货次数" required rules={[{required: true}]}>
                <InputNumber style={{width: '100%'}} placeholder='设置发货次数限制' />
            </Form.Item>
            {/*<Form.Item name="expireTime" label="过期时间">
                <DatePicker style={{width: '100%'}} placeholder='设置过期时间' />
            </Form.Item>*/}
            <Divider>开卡信息</Divider>
            {/*<Form.Item label="平台" name="externalPlatform" required rules={[{required: true}]}>
                <Input placeholder='输入平台名'/>
            </Form.Item>*/}
            <Form.Item label="平台" name="externalPlatform" required rules={[{required: true}]}>
                <Select loading={!platformTags} placeholder='选择平台'>
                    {platformTags?.map(v => <Select.Option
                        value={v.name}
                        key={v.id}>
                        {v.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="订单号" name="externalNumber" required rules={[{required: true}]}>
                <Input placeholder='输入关联订单号' name="type" disabled={!isNew&&!allowedUserIds.includes(user_id)} />
            </Form.Item>
            <Form.Item label="价格" name="value" required rules={[{required: true}]}>
                <InputNumber placeholder='输入关联订单价格' style={{width: '100%'}}
                             precision={currency.scale}
                             addonBefore={currency.symbol}/>
            </Form.Item>
            <Form.Item label="时间" name="time" required rules={[{required: true}]}>
                <DatePicker showTime style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item label="公司" name="companyId" required rules={[{required: true}]}>
                <Select loading={!companyTags} placeholder='选择订单关联公司'>
                    {companyTags?.map(v => <Select.Option
                        value={v.id}
                        key={v.id}>
                        {v.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="顾问" name="agentUser"
                       valuePropName='user'
                       required
                       rules={[{required: !searchAgentUserContent || searchAgentUserContent.length <= 0}]}>
                <UserPicker
                    disabled={agentUser !== undefined}
                    onSearch={setSearchAgentUserContent}
                    onPick={u => {
                        form.setFieldValue("agentUser", u);
                        setSearchAgentUserContent(undefined);
                    }} />
            </Form.Item>
            <Form.Item label="备注" name="summary">
                <TextArea placeholder='输入产品备注'/>
            </Form.Item>
        </>}>
        <Space direction='vertical' style={{marginLeft: 20}}>
            {/*      <Upload action="/upload.do" listType="picture-card" showUploadList={false} maxCount={1}>
                {cardIcon ? <Image src={cardIcon} preview={false}/> :
                    <div className={styles.uploadContainer}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>图标</div>
                    </div>}
            </Upload>*/}
            {repo ? <Upload action={repo.getUploadImagePath()}
                            headers={repo.getCommonHeaders()}
                            accept='image/*'
                            disabled
                            data={{type: 1}}
                            showUploadList={false}
                            onChange={e => {
                                const {file, fileList, event} = e;
                                if (file.response) responseTransfer(file.response, setCardCover);
                            }}
                            maxCount={1}>
                <div className={styles.coverContainer} style={cardImageStyle}>
                    {cardCover ? <Image src={cardCover} style={{objectFit:'cover'}}
                                        preview={false}/> : null}
                    <div className={`${styles.uploadContainer} ${styles.uploadArea}`} style={{color: cardCover ? undefined : 'black' }}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>{cardCover ? '更换' : undefined}卡面</div>
                    </div>
                </div>
            </Upload> : null}
            <Divider style={{marginBottom: 0}}>分配商品</Divider>
            <GoodPicker cardItems={cardItems}
                        onChange={(c, remove) => {
                            if (remove) setCardItems(p => p?.filter(v => v.good?.id != c.good?.id)); else if (cardItems) {
                                const index = cardItems.findIndex(v => v.good?.id === c.good?.id);
                                if (index >= 0) {
                                    cardItems[index] = c;
                                    setCardItems([...cardItems]);
                                } else setCardItems([...cardItems, c]);
                            } else setCardItems([c]);
                        }}
                        style={{width: 300}}/>
        </Space>
    </ContentEditor>;
}
