import {Input, Form, Upload, Divider, InputNumber, Typography, Space, Avatar} from "antd";
import {Good} from "@/repo/model/good";
import TextArea from "antd/lib/input/TextArea";
import {PlusOutlined} from "@ant-design/icons";
import {useUserSessionContext} from "@/repo/context";
import ContentEditor from "@/component/dialog/content-editor";
import {useEffect, useState} from "react";
import {responseTransfer} from "@/repo/response/transfer";

export default (props: {
    good?: Good,
    onComplete: (good?: Good) => void
}) => {
    const {good, onComplete} = props;
    const [cover, setCover] = useState(good?.cover);
    const [icon, setIcon] = useState(good?.icon);
    const {repo} = useUserSessionContext();
    useEffect(() => {
        if (good) {
            setCover(good.cover);
            setIcon(good.icon);
        }
    }, [good]);
    return <ContentEditor
        item={good}
        onComplete={onComplete}
        idGetter={item => item.id}
        onCreateItem={repo ? item => repo.createGood({...item, cover: cover, icon: icon}) : undefined}
        onUpdateItem={repo ? item => repo.updateGood(good?.id ?? '', {...item, cover: cover, icon: icon}) : undefined}
        titleBuilder={isNew => isNew ? '新增产品' : <Space direction="vertical" size={0}>
            编辑产品
            <Typography.Text type="secondary"
                             copyable={{tooltips: '复制产品ID'}}
                             style={{fontSize: 10}}>{good?.id}</Typography.Text>
        </Space>}
        contentBuilder={() => <>
            <Form.Item label="名称" required name="name" rules={[{required: true}]}>
                <Input placeholder='输入产品名称'/>
            </Form.Item>
            <Form.Item label="编号" name="number">
                <Input placeholder='输入产品编号'/>
            </Form.Item>
            <Form.Item label="类型" name="type">
                <Input placeholder='输入产品类型'/>
            </Form.Item>
            <Form.Item label="价格" name="value">
                <InputNumber placeholder='输入产品价格' style={{width: '100%'}} />
            </Form.Item>
            <Form.Item label="描述" name="description">
                <TextArea placeholder='输入产品描述'/>
            </Form.Item>
            <Form.Item label="小图" valuePropName="fileList">
                {repo ? <Upload action={repo.getUploadImagePath()}
                                headers={repo.getCommonHeaders()}
                                accept='image/*'
                                data={{type: 1}}
                                onChange={e => {
                                    const {file, fileList} = e;
                                    if (file.response && fileList && fileList.length > 0) responseTransfer(file.response, setIcon);
                                }}
                                onRemove={() => setIcon(good?.icon)}
                                maxCount={1}
                                listType="picture">
                    <Avatar size={100}
                            shape='square'
                            src={cover ? <img src={icon}/> : undefined}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </Avatar>
                </Upload>: null}
            </Form.Item>
            <Form.Item label="大图" valuePropName="fileList">
                {repo ? <Upload action={repo.getUploadImagePath()}
                                headers={repo.getCommonHeaders()}
                                accept='image/*'
                                data={{type: 1}}
                                onChange={e => {
                                    const {file, fileList} = e;
                                    if (file.response && fileList && fileList.length > 0) responseTransfer(file.response, setCover);
                                }}
                                onRemove={() => setCover(good?.cover)}
                                maxCount={1}
                                listType="picture">
                    <Avatar size={100}
                            shape='square'
                            style={{width: 200}}
                            src={cover ? <img src={cover}/> : undefined}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </Avatar>
                </Upload>: null}
            </Form.Item>
            <Divider/>
            <Form.Item label="备注" name="summary">
                <TextArea placeholder='输入产品备注'/>
            </Form.Item>
            <Form.Item label="排序" name="sortNo">
                <Input placeholder='输入产品序号，从小到大排序'/>
            </Form.Item>
        </>}>
    </ContentEditor>;
}
