import {Avatar, Divider, Empty, Image, InputNumber, Pagination, Select, Space, Tooltip, Typography} from "antd";
import {PlusOutlined, DeleteFilled, CloseOutlined, GiftOutlined} from "@ant-design/icons";
import {useUserSessionContext} from "@/repo/context";
import {CSSProperties, useEffect, useState} from "react";
import {Good} from "@/repo/model/good";
import {CardItem} from "@/repo/model/card";
import {PageList, RequestPageInfo} from "@/repo/model/common";
export default (props: {cardItems?: CardItem[],
    onChange?: (item: CardItem, remove?: boolean) => void,
    style?: CSSProperties}) => {
    const {cardItems, style, onChange} = props;
    const {repo} = useUserSessionContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchContent, setSearchContent] = useState<RequestPageInfo & {content?: string}>();
    const [searchResult, setSearchResult] = useState<PageList<Good>>();
    const hasContent = cardItems && cardItems.length > 0;
    const [productType, setProductType] = useState(1);

    useEffect(() => {
        if (searchContent && repo) {
            setLoading(true);
            repo.listGood({
                page: searchContent,
                search: searchContent.content,
                productType: productType,
                allProduct: false})
                .then(setSearchResult)
                .then(() => setOpen(true))
                .finally(() => setLoading(false));
        } else setSearchResult(undefined);
    }, [repo, searchContent, productType]);
    const count = searchResult?.count ?? 0;
    const offset = searchResult?.offset ?? 0;
    const size = searchResult?.size ?? 0;

    // const groupedOptions = searchResult?.items.reduce((groups, item) => {
    //     const groupKey = item.type; // 确保 productType 是 string 类型
    //     if (!groupKey) return groups; // 确保 groupKey 存在
    //
    //     // let groupKeyName = groupKey;
    //     // if(groupKey==="1"){
    //     //     groupKeyName = "主品";
    //     // }
    //     // if(groupKey==="3"){
    //     //     groupKeyName = "赠品";
    //     // }
    //
    //     const group = groups[groupKey] || [];
    //     group.push(item);
    //     groups[groupKey] = group;
    //     return groups;
    // }, {} as Record<string, Good[]>);

    const searchLine = <Select
        // onBlur={() => setOpen(false)}
        value={null}
        filterOption={false}
        style={{width:'100%', marginTop: 10}}
        onSearch={c => setSearchContent({content: c, offset: 0})}
        onChange={onChange ? v => {
            setOpen(false);
            const g = searchResult?.items.find(e => e.id === v);
            if (g) onChange({good: g, count: 1, consume: 0});
        }: undefined}
        onDropdownVisibleChange={setOpen}
        /*suffixIcon={open ? <CloseOutlined onClick={() => setOpen(false)}/>
            : <PlusOutlined onClick={!loading && repo
                ? () => setSearchContent({content: '', offset: 0}) : undefined
        } />}*/
        suffixIcon={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {open ? (
                    <CloseOutlined onClick={() => setOpen(false)} />
                ) : (
                    <>
                        <PlusOutlined onClick={!loading && repo ? () => {setProductType(1);setSearchContent({content: '', offset: 0})} : undefined} />
                        <GiftOutlined onClick={!loading && repo ? () => {setProductType(3);setSearchContent({content: '', offset: 0})} : undefined} style={{ marginLeft: 8 }} />
                    </>
                )}
            </div>
        }
        loading={loading}
        open={open}
        showSearch
        dropdownRender={offset > 0 || offset + size < count ? menu => (<>
            {menu}
            <Divider style={{margin:0}}/>
            <div
                style={{
                display: 'flex',
                flexDirection: "row-reverse",
                padding: "10px 0"
            }}><Pagination simple
                           size="small"
                           onChange={(p, s) => setSearchContent(op => ({...op,
                               offset: (p - 1) * s,
                               size: s
                           }))}
                           current={(offset / size) + 1}
                           pageSize={size}
                           total={count} /></div>
        </>) : undefined}
        placeholder='输入商品名称查找'>
        {searchResult?.items.map(v => <Select.Option
            disabled={cardItems?.find(e => e.good?.id === v.id)}
            value={v.id}
            key={v.id}>
            <GoodItem good={v}/>
        </Select.Option>)}

        {/*{groupedOptions && Object.keys(groupedOptions).map(group => (*/}
        {/*<Select.OptGroup key={group=='1'?'主品':'赠品'} label={group=='1'?'主品':'赠品'}>*/}
        {/*        {groupedOptions[group].map(v => (*/}
        {/*            <Select.Option*/}
        {/*                disabled={cardItems?.find(e => e.good?.id === v.id)}*/}
        {/*                value={v.id}*/}
        {/*                key={v.id}*/}
        {/*            >*/}
        {/*                <GoodItem good={v} />*/}
        {/*            </Select.Option>*/}
        {/*        ))}*/}
        {/*    </Select.OptGroup>*/}
        {/*))}*/}
    </Select>
    return <Space direction='vertical' style={style}>
        {hasContent ? <>{cardItems?.filter(v => (v.good?.status ?? -1) >= 0)
            .map(v => v.good ? <GoodCount
            key={v.good.id}
            good={v.good}
            defaultCount={v.count}
            onChanged={onChange ? (count, consume) => {
                v.count = count ?? v.count;
                v.consume = consume ?? v.consume;
                onChange(v);
            } : undefined}
            onDelete={onChange ? () => onChange(v, true) : undefined}
            {...v} /> : undefined)}
            {searchLine}
        </> : <Empty description={searchLine} style={{marginTop:10}}/>}
    </Space>
}

export const GoodItem = (props: {good: Good, style?: CSSProperties}) => {
    const {good, style} = props;
    return <Space align='center' size={4} style={style}>
        <Avatar icon={good.icon ? <Image src={good.icon}/> : undefined}>
            {good.name?.substring(0, 1)}
        </Avatar>
        <Space direction='vertical' size={0}>
            {good.name}
            <div>{good.description}</div>
        </Space>
    </Space>
}

const GoodCount = (props: {
    good: Good,
    count?: number,
    consume?: number,
    defaultCount?: number,
    onDelete?: () => void,
    onChanged?: (count?: number, consume?: number) => void
}) => {
    const {good, count, consume,
        defaultCount,
        onChanged,
        onDelete} = props;
    return <Space direction='vertical' size={10} style={{marginTop: 4}}>
        <Space>
            {onDelete ? <Tooltip title='删除商品'>
                <DeleteFilled onClick={onDelete}/>
            </Tooltip> : undefined}
            {good.name}
            <Typography.Text type='secondary'>{good.summary}</Typography.Text>
        </Space>
        <Space>
            <InputNumber
                addonBefore={<Typography.Text type='secondary'>总量</Typography.Text>}
                min={1}
                value={count}
                onChange={onChanged ? v => onChanged(v ?? defaultCount, consume) : undefined}
                placeholder={defaultCount?.toString() ?? '总数量'} />
            <InputNumber
                disabled
                addonBefore={<Typography.Text type='secondary'>消耗</Typography.Text>}
                value={consume}
                onChange={onChanged ? v => onChanged(count, v ?? undefined) : undefined}
                placeholder='已消耗数量' />
        </Space>
        <Divider style={{margin: 0}}/>
    </Space>
}
