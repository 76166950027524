import {
    Input, Button, Table,
    Space, Typography,
    Popconfirm, Switch, Tag, Modal
} from "antd";
import {useEffect, useState} from "react";
import SkuInput from './input';
import {SearchOutlined} from "@ant-design/icons";
import {useUserSessionContext} from "@/repo/context";
import {Good} from "@/repo/model/good";
import {InfoLine, Label} from "@/component/table";
import Cell from "@/component/table/cell";
import CommonTable from "@/component/table/common-table";
import {PageList, RequestPageInfo} from "@/repo/model/common";
import styles from './index.module.css';
import Repository from "@/repo/repository";

export default () => {
    const [editGood, setEditGood] = useState<Good>();
    const [datasource, setDatasource] = useState<PageList<Good>>();
    const [loading, setLoading] = useState(false);
    const {repo, hasPermission} = useUserSessionContext();
    const [requestPage, setRequestPage] = useState<RequestPageInfo>({offset: 0});
    const [search, setSearch] = useState<{ productName?: string }>();
    useEffect(() => {
        if (repo) {
            setLoading(true);
            repo.listGood({
                page: requestPage,
                ...search
            }).then(setDatasource)
                .finally(() => setLoading(false));
        }
    }, [repo, requestPage]);
    return <>
        <CommonTable
            onPageChange={(page, pageSize) => setRequestPage({...requestPage, offset: (page - 1) * pageSize, size: pageSize})}
            leftAction={
                hasPermission('4')
                    ? <Button type='primary' onClick={() => setEditGood({id: ''})}>新商品</Button>
                    : null}
            rightAction={<>
                <Input placeholder='商品名'
                       allowClear
                       value={search?.productName}
                       onChange={e => setSearch(p => ({...p, productName:  e.target.value}))}/>
                <Button icon={<SearchOutlined/>}
                        onClick={() => setRequestPage(p => ({...p, offset: 0}))}/>
            </>}
            loading={loading}
            {...datasource}>
            <Table.Column
                title={<Typography.Text ellipsis>产品</Typography.Text>}
                render={(e: Good) => <Cell {...e} coverName='产品图'/>}
            />
            <Table.Column
                title={<Typography.Text ellipsis>描述</Typography.Text>}
                render={(e: Good) => <InfoLine contentPlaceHolder='无内容'>
                    {e.description}
                </InfoLine>}
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>类型</Typography.Text>}
                render={(e: Good) => <InfoLine contentPlaceHolder='未设置类型'>
                    {e.type}
                </InfoLine>}
            />
            <Table.Column
                align="right"
                title={<Typography.Text ellipsis>价格</Typography.Text>}
                render={(e: Good) => <InfoLine contentPlaceHolder='未设置价格'>
                    {(typeof e.value === 'string' ? parseFloat(e.value) : (e.value ?? 0)) > 0 ? `${e.value} ${e.currency?.unit ?? e.currency?.code ?? ''}` : null}
                </InfoLine>}
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>排序</Typography.Text>}
                render={(e: Good) => <InfoLine contentPlaceHolder='未设置排序'>
                    {e.sortNo}
                </InfoLine>}
            />
            <Table.Column
                align='right'
                title={<Typography.Text ellipsis>状态</Typography.Text>}
                render={(e: Good) => <GoodAction good={e}
                                                 onGoodChanged={g => {
                                                     if (datasource) {
                                                         const index = datasource.items.findIndex(v => v.id === g.id);
                                                         if (index >= 0) {
                                                             datasource.items[index] = g;
                                                             setDatasource({...datasource, items: [...datasource.items]})
                                                         }
                                                     }
                                                 }}
                                                 repo={hasPermission('4') ? repo : undefined} />}
            />
            {hasPermission('4') && repo ? <Table.Column
                align='right'
                title={<Typography.Text ellipsis>操作</Typography.Text>}
                render={(e: Good) => <Space align='center'>
                    <Button
                        type='primary'
                        onClick={() => setEditGood(e)}
                        size='small'>编辑</Button>
                    <Popconfirm title={`删除商品 ${e.name}？`}
                                placement='bottomRight'
                                onConfirm={() => {
                                    repo.removeGood(e.id)
                                        .catch(e => Modal.error({content: e}))
                                        .then(() => {
                                            const ni = datasource?.items.filter(v => v.id !== e.id);
                                            if (ni) setDatasource({...datasource, items: ni});
                                        })
                                }}>
                        <Button
                            type='primary'
                            size='small'
                            danger>
                            删除
                        </Button>
                    </Popconfirm>
                </Space>}/> : null}
        </CommonTable>
        <SkuInput good={editGood} onComplete={good => {
            setEditGood(undefined);
            if (good && datasource) {
                const index = datasource.items.findIndex(v => v.id === good.id);
                let newItems;
                if (index >= 0) {
                    datasource.items[index] = good;
                    newItems = [...datasource.items];
                } else newItems = [good, ...datasource.items];
                setDatasource({...datasource, items: newItems});
            }
        }}/>
    </>
};

const GoodAction = (props: {good: Good, repo?: Repository, onGoodChanged: (good: Good) => void}) => {
    const {good, repo, onGoodChanged} = props;
    const status = good.status ?? 0;
    const actionName = status > 0 ? '下架' : '上架';
    const [committing, setCommitting] = useState(false);
    return <Space size={0} align="end" direction='vertical'>
        {repo ? <Popconfirm
            onConfirm={() => {
                setCommitting(true);
                const changeStatus = status > 0 ? 0 : 1;
                repo.updateGoodStatus(good.id, changeStatus)
                    .then(() => onGoodChanged({...good, status: changeStatus}))
                    .catch(e => Modal.error({content: e}))
                    .finally(() => setCommitting(false));
            }}
            title={`${actionName}商品 ${good.name}？`}
            placement='right'>
            <Switch
                checked={status > 0}
                loading={committing}
                checkedChildren='上架'
                unCheckedChildren='下架'
            />
        </Popconfirm> : <Tag color={status > 0 ? 'green' : 'red'}>{status > 0 ? '上架' : '下架'}</Tag>}
        {good.summary ? <Label>{good.summary}</Label> : null}
    </Space>;
}
